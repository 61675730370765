import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';

const TextIcon2ColumnContent = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    flex-direction: column;
    ${respondTo.md`
        flex-direction: row;
    `}
`;

const TextIcon2Column = ({children, className}) => {
    return (
        <TextIcon2ColumnContent className={className}>
            {children}
        </TextIcon2ColumnContent>
)};
export default TextIcon2Column;
