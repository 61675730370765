import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { Subtitle } from '../../util/typography';

const PageIntroductionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 20px 30px 20px;
    align-items: start;
    color: ${colors.charcoal};
    keytitle {
        color: ${colors.brandGreen};
        font-weight: 700;
    }
    lentitle {
        color: ${colors.mulberry};
        font-weight: 700;
    }
    ${respondTo.md`
        padding: 30px 30px 40px 30px;
        max-width: 1032px;
        margin: 0 auto;
    `}
`;

const PageIntroduction = ({eyebrow, children, className}) => {
    return (
        <PageIntroductionContainer className={className}>
            {eyebrow && <Subtitle>{eyebrow}</Subtitle>}
            {children}
        </PageIntroductionContainer>
)};
export default PageIntroduction;