import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { H6Style, Body } from '../../util/typography';
import phoneIcon from '../../assets/utility/icons/icon-phone.svg';

const SecondaryCalloutContent = styled.div`
    color: ${colors.charcoal};
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    margin: 0 30px 10px 20px;
    align-items: stretch;
    border: 1px solid ${colors.silver};
    box-shadow: 10px 10px 0px 0px ${colors.brandGreen};
    border-radius: 8px;
    img {
        align-self: flex-start;
        ${({noFilter}) => noFilter ? `` : `filter: invert(11%) sepia(59%) saturate(7473%) hue-rotate(314deg) brightness(68%) contrast(104%)`};
    }
    ${respondTo.md`
        max-width: 962px;
        flex-direction: row;
        margin: 0 auto;
        margin-bottom: 10px;
    `}
`;

const Title = styled.div`
    ${H6Style};
    font-weight: 700;
`;

const TextArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    a {
        color: ${colors.greenDark};
        text-decoration: none;
        white-space: nowrap;
        text-wrap: nowrap;
    }
    ${respondTo.md`
        gap: 6px;
    `}
`;

const SecondaryCallout = ({children, icon, noIcon, className}) => {
    return (
        <SecondaryCalloutContent noFilter={icon ? true : false} className={className}>
            {icon ? <img src={icon} alt='' /> : !noIcon ? <img src={phoneIcon} alt='' /> : <></>}
            <TextArea>
                {children && !Array.isArray(children) ? <Title>{children}</Title> : children && <Title>{children[0]}</Title>}
                {children && children.length > 1 ? <Body>{children.slice(1)}</Body> : <></>}
            </TextArea>
        </SecondaryCalloutContent>
)};
export default SecondaryCallout;